.backdrop {
  display: none; /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.backdrop.show {
  display: block; /* Show the backdrop when 'show' class is added */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Below the modal but above other content */
}

.popup-overlay {
  width: 335px;
  height: 386px;
  flex-shrink: 0;
  border-radius: 26px;
  background: #fff;
  z-index: 6000;
  position: fixed;
  margin-left: 21px;
  margin-right: 19px;
  margin-bottom: 300px;
}

.image-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the space between images */
  justify-content: center; /* Center the images container */
}

.image-preview {
  flex: 0 0 calc(25% - 10px); /* Four images per row, accounting for the gap */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-preview img {
  margin-top: 20px;
  width: 69.16px;
  height: 182px;
  flex-shrink: 0;
}

.image-preview button {
  margin-top: 20px;
  border: 0;
  background-color: #fff;
  display: flex;
  width: 43px;
  height: 43px;
  padding: 3.583px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.close_icon {
  width: 20px;
  height: 35.833px;
  flex-shrink: 0;
}
