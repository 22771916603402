.loading-spinner {
    position: absolute;
  top: 40%;
  left: 47%;

    width: 200px;
    height: 200px;
    border: 25px solid #6823D1;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    z-index:9999;
    display: block;
    }

    
@media screen and (max-width: 768px) {
    .loading-spinner {
        position: absolute;
        top: 40%;
        left: 40%;
          width: 100px;
          height: 100px;
          border: 10px solid #6823D1;
          border-bottom-color: transparent;
          border-radius: 50%;
          display: inline-block;
          box-sizing: border-box;
          animation: rotation 1s linear infinite;
          z-index:9999;
          display: block;
    }
  }
    
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 

    